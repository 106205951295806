import { useState, useEffect } from 'react';
import { Szunetnapok } from '../../../models/szunetnapok.interface';
import { TimeResponse } from '../../../models/time.response';
import { Szunetnap } from '../../../models/szunetnap.interface';
import { useOpen } from '../../../hooks/Open';

const Opening = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [open, setOpen] = useState<number>(7);
    const [close, setClose] = useState<number>(16);

    const getSzunetnapok = (): Promise<Szunetnapok> => {
        return fetch('./szunetnapok.json')
            .then((response) => response.json());
    }

    const getServerDateTime = (): Promise<TimeResponse> => {
        return fetch('https://szakituzep.hu/time.php')
            .then((response) => response.json());
    }

    const setOpeningIfError = () => {
        const now = new Date();
        // weekend
        if(now.getDay() === 6 || now.getDay() === 7) {
            return setIsOpen(false);
        }

        if(now.getHours() >= open && now.getHours() < close) {
            return setIsOpen(true);
        } else {
            return setIsOpen(false);
        }
    }

    const setOpeningIfSuccess = (szunetnapok: Szunetnapok, currentDateTime: TimeResponse) => {
        if(currentDateTime.year === szunetnapok.year) {
            //setOpeningIfSuccess(szunetnapok, timeResponse);
        } else {
            console.error(`${szunetnapok.year} a the year of the szunetnapok is different from the current year`)
            return setOpeningIfError();
        }

        var currentSzunetnap = szunetnapok.days.filter((szunetnap: Szunetnap) => {
            return currentDateTime.date === szunetnap.date && szunetnap.type === "1";
        });

        if(currentSzunetnap.length > 0) {
            return setIsOpen(false);
        }
        
        const now = new Date(currentDateTime.datetime);       
                
        // weekend
        if(currentDateTime.week_of_day === 6 || currentDateTime.week_of_day === 7 ) {
            return setIsOpen(false);
        }        

        if(now.getHours() >= open && now.getHours() < close) {
            return setIsOpen(true);
        } else {
            return setIsOpen(false);
        }
    }

    useEffect(() => {
        getServerDateTime()
            .then((timeResponse: TimeResponse) => {
                getSzunetnapok()
                    .then((szunetnapok: Szunetnapok) => {
                        setOpeningIfSuccess(szunetnapok, timeResponse);      
                        setInterval(() => {
                            setOpeningIfSuccess(szunetnapok, timeResponse); 
                        }, 15000);
                    }, 
                    (error) => {
                        console.error(error);
                        setOpeningIfError();
                    });       
                });         
    });
    
    
    const setClockIconColor = (isOpen: boolean): string => {
        return isOpen ? "text-success" : "text-primary";
    }

    return (
            <div className="d-inline-flex align-items-center">
                <i 
                    className={`bi bi-alarm fs-1 me-3 ${setClockIconColor(isOpen)}`}
                ></i>
                <div className="text-start">
                    <h6 className="text-uppercase fw-bold">
                        Nyitvatartás&nbsp;&nbsp;
                        {isOpen && (<span className="badge bg-success">Nyitva</span>)}
                        {!isOpen && (<span className="badge bg-danger">Zárva</span>)}
                    </h6>
                    <span>Hétfőtől - péntekig 07 - 16 h</span>
                </div>
            </div>


            // <div className="d-inline-flex align-items-center">
            //     <i 
            //         className={`bi bi-alarm fs-1 me-3 ${setClockIconColor(isOpen)}`}
            //     ></i>
            //     <div className="text-start">
            //         <h6 className="text-uppercase fw-bold">
            //             Nyitvatartás&nbsp;&nbsp;
            //             {isOpen && (<span className="badge bg-success">Nyitva</span>)}
            //             {!isOpen && (<span className="badge bg-danger">Zárva</span>)}
            //         </h6>
            //         <span>Hétfőtől - péntekig 07 - 16 h</span>
            //     </div>
            // </div>
    );
}

export default Opening;