import Email from '../template/topbarItems/Email';
import Location from '../template/topbarItems/Location';
import Opening from '../template/topbarItems/Opening';
import PhoneCall from '../template/topbarItems/PhoneCall';
const ContactSection = () => {
    return (
        <div className="container-fluid py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{maxWidth: 600}}>
            <h1 className="display-5 text-uppercase mb-4">
                Kérlek <span className="text-primary">nyugodtan</span> vedd fel velünk a kapcsolatot!
            </h1>
        </div>
        <div className="row gx-0 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{height: 600}}>
                <iframe className="w-100 h-100"
                    src="https://maps.google.com/maps?q=1225%20Budapest,%20Hugonnay%20Vilma%20utca%205&t=&z=12&ie=UTF8&iwloc=&output=embed"
                       aria-hidden="false" style={{border:0}}></iframe>
            </div>
            <div className="col-lg-6">
                <div className="row gx-5 p-5">
                    <div className="col-lg-12 border-start border-end py-3">
                        <Location />
                    </div>
                    <div className="col-lg-12 border-start border-end py-3">
                        <Opening />
                    </div>                    
                    <div className="col-lg-12 border-start border-end py-3">
                        <Email />
                    </div>
                    <div className="col-lg-12 border-start border-end py-3">
                        <PhoneCall />
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default ContactSection;