const PhoneCall = () => {
    return (
        <div className="d-inline-flex align-items-center">
            <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
            <div className="text-start">
                <h6 className="text-uppercase fw-bold">Hívj minket!</h6>
                <span>+36 30 417 1770</span>
            </div>
        </div>
    );
}

export default PhoneCall;