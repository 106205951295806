import React from "react";
import BackToTop from "./BackToTop";
import Footer from "./Footer";
import FooterBottom from "./FooterBottom";
import Navbar from "./Navbar";
import Topbar from "./Topbar";

const Template: React.FC = (props) => {
    return (
        <div>
            <Topbar />
            <Navbar />
            {props.children}
            <Footer />
            <FooterBottom />
            <BackToTop />
        </div>)
}

export default Template;