const ProductGroups = () => {
    return (
    <div className="container-fluid bg-light py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{maxWidth: 600}}>
            <h1 className="display-5 text-uppercase mb-4">
                Néhány <span className="text-primary">termékcsoport</span> a kínálatunkból
            </h1>
        </div>
        <div className="row g-5 portfolio-container">
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/betonaru.jpg" alt="" />
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Beton árú</p>
                        <span className="text-body">
                            <i className="fa fa-map-marker-alt text-primary me-2"></i>
                            Zsalukő, pincefalazó
                        </span>
                    </a>
                    <a className="portfolio-btn" href="img/betonaru.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item second">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/portfolio-2.jpg" alt="" />
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Ömlesztett anyagok</p>
                        <span className="text-body">
                            <i className="fa fa-map-marker-alt text-primary me-2"></i>
                                Sóder, homok, kavics
                        </span>
                    </a>
                    <a className="portfolio-btn" href="img/portfolio-2.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/falazoblokkok_ytong_tegla.jpg" alt="" />
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Falazóblokkok</p>
                        <span className="text-body">
                            <i className="fa fa-map-marker-alt text-primary me-2"></i>
                            Tégla, Ytong
                        </span>
                    </a>
                    <a className="portfolio-btn" href="img/falazoblokkok_ytong_tegla.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item second">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/termekcsoport_ragasztok.jpg" alt="" />
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Ragasztók</p>
                        <span className="text-body">
                            <i className="fa fa-map-marker-alt text-primary me-2"></i>
                            Csemperagasztó, Ytongragasztó
                        </span>
                    </a>
                    <a className="portfolio-btn" href="img/termekcsoport_ragasztok.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/hoszigetelo_rendszer.jpg" alt="" />
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Hőszigetelő rendszerek</p>
                        <span className="text-body">
                            <i className="fa fa-map-marker-alt text-primary me-2"></i>
                            Thermosystem 5cm 10cm 15cm 20cm, kőzetgyapot
                        </span>
                    </a>
                    <a className="portfolio-btn" href="img/hoszigetelo_rendszer.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item second">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/faaru.jpg" alt="" />
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Faárú</p>
                        <span className="text-body">
                            <i className="fa fa-map-marker-alt text-primary me-2"></i>
                            Tetőléc, gerenda, OSB lemez
                        </span>
                    </a>
                    <a className="portfolio-btn" href="img/faaru.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    );
}

export default ProductGroups;