import { NavLink, Link } from "react-router-dom";
import Newsletter from "./Newsletter";

const Footer = () => {
    return (
    <div className="footer container-fluid position-relative bg-dark bg-light-radial text-white-50 py-6 px-5">
        <div className="row g-5">
            <div className="col-lg-6 pe-lg-5">
                <Link to={'/'} className="navbar-brand">
                    <h1 className="m-0 display-4 text-uppercase text-white">
                        <i className="bi bi-building text-primary me-2"></i>
                        SZAKITÜZÉP
                    </h1>
                </Link>
                <p><i className="fa fa-map-marker-alt me-2"></i>1225 Budapest, Hugonnay Vilma utca 5</p>
                <p><i className="fa fa-phone-alt me-2"></i>+36 30 417 1770</p>
                <p><i className="fa fa-envelope me-2"></i>iroda@szakituzep.hu</p>                
            </div>
            <div className="col-lg-6 ps-lg-5">
                <div className="row g-5">
                    <div className="col-sm-6">
                        <h4 className="text-white text-uppercase mb-4">Gyorslinkek</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <Link className="text-white-50 mb-2" to={'/'}>
                                <i className="fa fa-angle-right me-2"></i>
                                Nyitólap
                            </Link>
                            <Link className="text-white-50 mb-2" to={'/kapcsolat'}>
                                <i className="fa fa-angle-right me-2"></i>
                                Kapcsolat
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <h4 className="text-white text-uppercase mb-4">Népszerű linkek</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <Link className="text-white-50 mb-2" to={'/'}>
                                <i className="fa fa-angle-right me-2"></i>
                                Nyitólap
                            </Link>
                            <Link className="text-white-50 mb-2" to={'/kapcsolat'}>
                                <i className="fa fa-angle-right me-2"></i>
                                Kapcsolat
                            </Link>    
                        </div>
                    </div>
                    <div className="col-sm-12">
                        {/* <Newsletter /> */}
                    </div>
                </div>
            </div>
        </div>
    </div>    
    );
}

export default Footer;