const Location = () => {
    return (
        <div className="d-inline-flex align-items-center">
            <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
            <div className="text-start">
                <h6 className="text-uppercase fw-bold">Telephely</h6>
                <span>1225 Budapest, Hugonnay Vilma utca 5</span>
            </div>
        </div>
    );
}

export default Location;