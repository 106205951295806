import Carousel from "../components/open/Carousel";
import ProductGroups from "../components/productGroups/ProductGroups";
import Services from "../components/services/Services";
import Template from "../components/template/Template";
import Testimonial from "../components/Testimonial";
import About from "./About";
import Team from '../components/team/Team';
import {Helmet} from 'react-helmet';

// https://developers.facebook.com/blog/post/2013/06/05/platform-updates--new-open-graph-business-object-type/
const Open = () => {
    return (        
        <Template>  
            <Helmet>
                <title>Építőanyag kereskedés - SZAKITÜZÉP</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <meta content="építőanyag, tüzép, kereskedés, építkezés, ytong, bramac" name="keywords" />
                <meta content="Építőanyag kereskedés - 1225 Bp. Hugonnay Vilma u. 5" name="description" />
                {/* Facebook open graph */}
                <meta property="og:site_name" content="SZAKITÜZÉP" />
                <meta property="og:title" content="Építőanyag kereskedés - SZAKITÜZÉP" />
                <meta property="og:url" content="https://szakituzep.hu" />
                {/* <meta property="og:type" content="business.business" /> */}
                <meta property="og:type" content="business.business" />
                <meta property="og:description" content="Építőanyag kereskedés - 1225 Bp. Hugonnay Vilma u. 5" />
                <meta property="og:image" content="https://szakituzep.hu/img/fb_og_img/open.jpg" />
                <meta property="og:email" content="iroda@szakituzep.hu" />
                <meta property="og:locale" content="hu_HU" />
                {/* Facebook open graph business */}
                <meta property="place:location:latitude" content="47.392060" />
                <meta property="place:location:longitude" content="18.988650" />
                <meta property="business:contact_data:street_address" content="Hugonnay Vilma utca 5" />
                <meta property="business:contact_data:country" content="Magyarország" />
                <meta property="business:contact_data:postal_code" content="1225" />
                <meta property="business:contact_data:locality" content="Budapest" />
                <meta property="business:contact_data:email" content="iroda@szakituzep.hu" />
                <meta property="business:contact_data:phone_number" content="+36 30 417 1770" />
                <meta property="business:contact_data:website" content="szakituzep.hu" />
            </Helmet>          
            <Carousel />
            <About />
            <Services />
            <ProductGroups />
            <Team />
        </Template>
    );
}

export default Open;