const Email = () => {
    return (
        <div className="d-inline-flex align-items-center">
            <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
            <div className="text-start">
                <h6 className="text-uppercase fw-bold">Email</h6>
                <span>iroda@szakituzep.hu</span>
            </div>
        </div>
    );
}

export default Email;