import Template from "../components/template/Template";
import {Helmet} from 'react-helmet';

const NotFound = () => {
    return (
        <Template>
            <Helmet>
                <title>Az oldal nem található (404) - SZAKITÜZÉP</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <meta content="építőanyag, tüzép, kereskedés, építkezés, ytong, bramac" name="keywords" />
                <meta content="Építőanyag kereskedés - 1225 Bp. Hugonnay Vilma u. 5" name="description" />
                {/* Facebook open graph */}
                <meta property="og:site_name" content="SZAKITÜZÉP" />
                <meta property="og:title" content="Az oldal nem található (404) - SZAKITÜZÉP" />
                <meta property="og:url" content="https://szakituzep.hu" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Építőanyag kereskedés - 1225 Bp. Hugonnay Vilma u. 5" />
                <meta property="og:image" content="https://szakituzep.hu/img/fb_og_img/contact.jpg" />
                <meta property="og:email" content="iroda@szakituzep.hu" />
                <meta property="og:locale" content="hu_HU" />
                {/* Facebook open graph business */}
                <meta property="place:location:latitude" content="47.392060" />
                <meta property="place:location:longitude" content="18.988650" />
                <meta property="business:contact_data:street_address" content="Hugonnay Vilma utca 5" />
                <meta property="business:contact_data:country" content="Magyarország" />
                <meta property="business:contact_data:postal_code" content="1225" />
                <meta property="business:contact_data:locality" content="Budapest" />
                <meta property="business:contact_data:email" content="iroda@szakituzep.hu" />
                <meta property="business:contact_data:phone_number" content="+36 30 417 1770" />
                <meta property="business:contact_data:website" content="szakituzep.hu" />
            </Helmet>
            <h1>Page not found (404)</h1>
        </Template>
    );
}

export default NotFound;