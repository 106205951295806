import { Link } from "react-router-dom";

const FooterBottom = () => {
    return (
        <div className="container-fluid bg-dark bg-light-radial text-white border-top border-primary px-0">
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="py-4 px-5 text-center text-md-start">
                    <p className="mb-0">&copy; 
                    <Link className="text-primary" to={'/'}>
                        Szakitüzép
                    </Link>. Minden jog fenntartva.</p>
                </div>
                <div className="py-4 px-5 bg-primary footer-shape position-relative text-center text-md-end">
                    <p className="mb-0">Designed by <a className="text-dark" href="mailto:poroszkai.attila@gmail.com">Poroszkai Attila</a></p>
                    <p className="mb-0">Hosting by <a className="text-dark" href="https://www.profitarhely.hu/">Profitárhely</a></p>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;