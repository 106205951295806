const Services = () => {
    return (
    <div className="container-fluid bg-light py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{maxWidth: 600}}>
            <h1 className="display-5 text-uppercase mb-4">
                <span className="text-primary">Szolgáltatásaink</span>
            </h1>
        </div>
        <div className="row g-5">
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white d-flex flex-column align-items-center text-center">
                    <img className="img-fluid" src="img/service-1.jpg" alt="" />
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-building text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Építőanyag kiszállítás</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                    <img className="img-fluid" src="img/service-2.jpg" alt="" />
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-home text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Mennyiség kiszámolás</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                    <img className="img-fluid" src="img/service-3.jpg" alt="" />
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-drafting-compass text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Szaktanácsadás</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                    <img className="img-fluid" src="img/service-4.jpg" alt="" />
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-palette text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Tervezési segítség</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                    <img className="img-fluid" src="img/service-5.jpg" alt="" />
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-tools text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Építőipari munkák</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                    <img className="img-fluid" src="img/service-6.jpg" alt="" />
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-paint-brush text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Kivitelező ajánlás</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Services;