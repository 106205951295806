import { NavLink, Link } from "react-router-dom";

const Navbar = () => {
    return (
    <div className="container-fluid sticky-top bg-dark bg-light-radial shadow-sm px-5 pe-lg-0">
        <nav className="navbar navbar-expand-lg bg-dark bg-light-radial navbar-dark py-3 py-lg-0">
            <Link to={'/'} className="navbar-brand">
                <h1 className="m-0 display-4 text-uppercase text-white">
                    <i className="bi bi-building text-primary me-2"></i>
                    SZAKITÜZÉP
                </h1>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                    <NavLink
                      to={'/'} 
                      className={({isActive}) => [
                          "nav-item nav-link",
                          isActive ? "active" : null
                      ].filter(Boolean).join(" ")}
                      >
                        Nyitólap
                    </NavLink>
                    
                    <NavLink
                      to={'/kapcsolat'}
                      className={({isActive}) => [
                        "nav-item nav-link",
                        isActive ? "active" : null
                      ].filter(Boolean).join(" ")}
                     >
                        Kapcsolat
                    </NavLink>
                </div>
            </div>
        </nav>
    </div>
    )
}

export default Navbar;