const About = () => {
    return (
    <div className="container-fluid py-6 px-5">
        <div className="row g-5">
            <div className="col-lg-7">
                <h1 className="display-5 text-uppercase mb-4">Már <span className="text-primary">25 éve</span> a lakosság és az építőipar szolgálatában</h1>
                <h4 className="text-uppercase mb-3 text-body">Várjuk megkeresését, hívjon telefonon, vagy keressen fel telephelyünkön!</h4>
                <p>Folyamatos és megbízható szállítási szolgálatásunk segítségével - ahol a teherautók a tulajdonunkban vannak, és
                    nem megbízhatatlan bérfuvarosok szállítanak - biztosítjuk, hogy építkezése nem akad el, és nem veszít pénzt,
                    mert a rendelése nem ért oda időben.</p>
                <div className="row gx-5 py-2">
                    <div className="col-sm-6 mb-2">
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Személyre szabott ajánlatok</p>
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Rugalmas kiszolgálás</p>
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Házhozszállítás</p>
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Szaktanácsadás</p>
                        <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Minőségi építőanyagok</p>
                    </div>                   
                </div>
                <img src="img/signature.jpg" alt="" />
            </div>
            <div className="col-lg-5 pb-5" style={{minHeight: 400}}>
                <div className="position-relative bg-dark-radial h-100 ms-5">
                    <img className="position-absolute w-100 h-100 mt-5 ms-n5" src="img/about.jpg" style={{objectFit: 'cover'}} />
                </div>
            </div>
        </div>
    </div>
    );
}

export default About;