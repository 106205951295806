const Team = () => {
    return (
    <div className="container-fluid py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{maxWidth: 600}}>
            <h1 className="display-5 text-uppercase mb-4">
                Csapatunk 
                
            </h1>
        </div>
        <div className="row g-5">
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="row g-0">
                    <div className="col-10" style={{minHeight: 300}}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100" src="img/team-1.jpg" style={{objectFit: 'cover'}} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-between bg-light">
                            
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-light p-4">
                            <h4 className="text-uppercase">Korozmán László</h4>
                            <span>Sofőr, raktáros</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="row g-0">
                    <div className="col-10" style={{minHeight: 300}}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100" src="img/poroszkai.attila.info.webp" style={{objectFit: 'cover'}} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-between bg-light">
                            
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-light p-4">
                            <h4 className="text-uppercase">ifj. Poroszkai Attila</h4>
                            <span>Informatika</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="row g-0">
                    <div className="col-10" style={{minHeight: 300}}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100" src="img/id_PoroszkaiAttila.jpg" style={{objectFit: 'cover'}} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-between bg-light">
                            
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-light p-4">
                            <h4 className="text-uppercase">id. Poroszkai Attila</h4>
                            <span>Kereskedelmi igazgató</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="row g-0">
                    <div className="col-10" style={{minHeight: 300}}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100" src="img/PoroszkaiTamas_profile.jpg" style={{objectFit: 'cover'}} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="h-100 d-flex flex-column align-items-center justify-content-between bg-light">
                            
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bg-light p-4">
                            <h4 className="text-uppercase">Poroszkai Tamás</h4>
                            <span>Ügyvezető</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Team;