import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Open from './pages/Open';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';


// todo https://www.npmjs.com/package/react-helmet
// todo https://mahdikarimipour.com/blog/spa-react-seo
// todo https://blog.logrocket.com/adding-dynamic-meta-tags-react-app-without-ssr/
// todo https://stackoverflow.com/questions/55884214/open-graph-meta-tags-and-seo-in-a-react-spa
// todo https://stackoverflow.com/questions/66603051/react-cra-meta-og-tags-for-specific-page-spring-boot

function App() {
  return (
    <Routes>
      <Route path='/' element={<Open />} />
      <Route path='/kapcsolat' element={<Contact />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default App;
