import Email from "./topbarItems/Email";
import Location from "./topbarItems/Location";
import Opening from "./topbarItems/Opening";
import PhoneCall from "./topbarItems/PhoneCall";

const Topbar = () => {
    return (
    <div className="container-fluid px-5 d-none d-lg-block">
        <div className="row gx-5">
            <div className="col-lg-3 text-center border-end py-3">
                <Location />
            </div>
            
            <div className="col-lg-3 text-center border-end py-3">
                <Opening />
            </div>
            <div className="col-lg-3 text-center border-end py-3">
                <Email />
            </div>
            <div className="col-lg-3 text-center py-3">
                <PhoneCall />
            </div>
        </div>
    </div>
    );
}

export default Topbar;